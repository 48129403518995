import React from "react";
import { Carousel } from "react-responsive-carousel";
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './App.css';

const CustomizedCakes = () => {
    const customCakes = [
        {
            image: "https://bakingtimeneiva.com/wp-content/imagenes-productos-menu/item-26.jpg",
            description: 'Letreros ($5.000)',
        },
        {
            image: 'https://bakingtimeneiva.com/wp-content/imagenes-productos-menu/item-27.jpg',
            description: 'Volcán ($3.000)',
        },
        {
            image: 'https://bakingtimeneiva.com/wp-content/imagenes-productos-menu/item-28.png',
            description: 'Vela ($2.000)',
        },
        {
            image: 'https://bakingtimeneiva.com/wp-content/imagenes-productos-menu/item-29.jpg',
            description: 'Flores ($15.000)',
        },
        {
            image: 'https://bakingtimeneiva.com/wp-content/imagenes-productos-menu/item-30.jpg',
            description: 'Stikers ($10.000)',
        },
        {
            image: 'https://bakingtimeneiva.com/wp-content/imagenes-productos-menu/item-31.jpg',
            description: 'Acetato + moños ($5.000)',
        },
    ];

    return (
        <div className="customized-cakes-container centered-container">
            <h2>Opciones de Pasteles Personalizados</h2>
            <Carousel showThumbs={false} infiniteLoop={true}>
                {customCakes.map((cake, index) => (
                    <div key={index}>
                        <img src={cake.image} alt={cake.description} />
                        <p className="legend">{cake.description}</p>
                    </div>
                ))}
            </Carousel>
        </div>
    );
};

export default CustomizedCakes;