import React, { useState } from "react";
import logo from "./logo.JPG";
import items from "./data";
import Menu from "./Menu";
import Categories from "./Categories";
import { CakeFlavors, HealthyFlavors, StuffedFlavors } from './Flavors'; // Asegúrate de que la ruta sea correcta
import CustomizedCakes from "./CustomizedCakes"; //Importa el componente CustomizedCakes
import OrderForm from "./OrderForm"; //Importa el componente PedidoForm
import SectionNotas from "./SectionNota"; //Importa el componente SectionNota
import Footer from "./Footer";
import './App.css';

const allCategories = ["all", ...new Set(items.map((item) => item.category))];

const App = () => {
  const [menuItems, setMenuItems] = useState(items);
  const [activeCategory, setActiveCategory] = useState("");
  const [categories] = useState(allCategories);

  const filterItems = (category) => {
    setActiveCategory(category);
    if (category === "all") {
      setMenuItems(items);
      return;
    }
    const newItems = items.filter((item) => item.category === category);
    setMenuItems(newItems);
  };

  return (
    <main>
      <section className="menu section">
        <div className="title">
          <img src={logo} alt="logo" className="logo" />
          <h2>Menú Baking-time</h2>
          <div className="underline"></div>
        </div>
        <Categories
          categories={categories}
          activeCategory={activeCategory}
          filterItems={filterItems}
        />
        <Menu items={menuItems} />
        <CakeFlavors />
        <HealthyFlavors />
        <StuffedFlavors /> {/* Agrega el componente de sabores de rellenos */}
        <CustomizedCakes />
        <OrderForm />
        <SectionNotas />
      </section>
      <Footer />
    </main>
  );
};

export default App;
