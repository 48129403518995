import React from 'react';
import './App.css'; // Asegúrate de importar tus estilos CSS

function SeccionNotas() {
    return (
        <div className="seccion-notas">
            <h3 className="titulo-notas">Notas:</h3>
            <p className="parrafo-notas">
                Estimado(a) cliente, si el diseño de pastel que deseas contiene flores que solo son traídas del mercado local bajo pedido los martes o viernes después de las 3pm, no por ello se puede garantizar el tono y especie floral ya que dependemos de disponibilidad en los cultivos. Teniendo en cuenta lo anterior, si deseas el diseño del pastel, haremos todo lo posible para que quede similar en tonos y especies de flores que se logren conseguir ya sea bajo pedido en el mercado local, esto hace que cada diseño floral en los pasteles sea único.
            </p>
            <p className="parrafo-notas">
                ¿Estás de acuerdo?
            </p>
            <p className="parrafo-notas">
                Si aún no lograste culminar el proceso de programación y compra de algún producto, déjanos un mensaje con los datos, y a nuestro regreso, te llamaremos y te daremos una atención personalizada. Recuerda que una vez realizado el pago del 50% se podrá programar el pedido, sin embargo, si el mismo se realiza ese día, No podremos garantizar entrega de pedidos en horarios de la mañana.
            </p>
            <h3 className="titulo-notas">MEDIOS DE PAGO</h3>
            <ul className="lista-pagos">
                <li>✓ Nequi: Cuenta: 314 237 5165</li>
                <li>✓ Daviplata: Cuenta: 314 237 5165</li>
                <li>✓ Colpatria: Cuenta de ahorros: 7452002367</li>
                <li>✓ Bancolombia: Cuenta de ahorros: 07600004008</li>
            </ul>
            <p className="parrafo-notas">
                ¡Recuerda consignar el valor del producto más el envío!
            </p>
            <p className="parrafo-notas">
                ¡GRACIAS POR ELEGIRNOS!<br />
                Gracias por comunicarte con BAKING TIME NEIVA pastelería gourmet. Esperamos poder servirte nuevamente en una próxima oportunidad.
            </p>
        </div>
    );
}

export default SeccionNotas;
