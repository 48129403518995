import React, { useState } from 'react';

function OrderForm() {
    //Definimos el estado inicial del formulario
    const initialFormData = {
        producto: '',
        fecha: '',
        hora: '',
        domicilio: '',
        direccion: '',
        tamano: '',
        sabor: '',
        email: '',
        relleno: '',
        hasDecoracion: 'No',
        decoracion: ''
    };

    //Usamos el estado para mantener los datos del formulario
    const [formData, setFormData] = useState(initialFormData);

    //Función para enviar el pedido por whatsapp y limpiar el formualrio
    const handleWhatsApp = () => {
        // Crea un mensaje de WhatsApp con los datos del formulario
        const message = `
         Pedido de ${formData.producto} 🍰 a Baking Time 
         Fecha: ${formData.fecha}
         Hora: ${formData.hora}
         Domicilio: ${formData.domicilio}
         Dirección: ${formData.direccion}
         Tamaño: ${formData.tamano}
         Sabor: ${formData.sabor}
         Relleno: ${formData.relleno}
         Decoración: ${formData.decoracion}
         Email: ${formData.email}
     `;

        // Abre WhatsApp con el mensaje predefinido
        window.open(`https://api.whatsapp.com/send?phone=3142375165&text=${encodeURIComponent(message)}`);

        // Restablece el formulario a su estado inicial
        setFormData(initialFormData);
    };

    //Función para manejar cambios en los campos del formulario
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    //Función para manejar cambios en el campo 'producto'
    const handleProductoChange = (e) => {
        const { value } = e.target;
        setFormData({ ...formData, producto: value });
    };

        //Función para manejar cambios en el campo 'decoracion'
        const handleDecoracionChange = (e) => {
            const { value } = e.target;
            setFormData({ ...formData, decoracion: value });
        };
    

    return (
        <div>
            <h2>Solicitud de Pedido</h2>
            <form>
                <div>
                    <label>Producto:</label>
                    {/*Campo de selección para el tipo de producto*/}
                    <select name="producto" value={formData.producto} onChange={handleProductoChange}>
                        <option value="" disabled>seleccione el producto</option>
                        <option value="pasteles">Pasteles</option>
                        <option value="minicakes">Minicakes</option>
                        <option value="porcionTorta">Porción de Torta</option>
                        <option value="agua">Agua</option>
                        <option value="sangria">Sangría</option>
                        <option value="cheesecakes">Cheesecakes</option>
                        <option value="latte">Latte</option>
                        <option value="cafeExpreso">Café Expreso</option>
                        <option value="sodaFrutosRojos">Soda Frutos Rojos</option>
                        <option value="teHatzu">Té Hatzu</option>
                        <option value="obleasColores">Obleas de Colores</option>
                        <option value="galletas">Galletas</option>
                        <option value="cupcakes">Cupcakes</option>
                        {/* Agrega otros productos aquí */}
                    </select>
                </div>

                <div>
                    <label>Fecha:</label>
                    <input
                        type="date"
                        name="fecha"
                        value={formData.fecha}
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <label>Hora:</label>
                    <input
                        type="time"
                        name="hora"
                        value={formData.hora}
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <label>Domicilio:</label>
                    <select name="domicilio" value={formData.domicilio} onChange={handleChange}>
                        <option value="" disabled>Deseas domicilio</option>
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                </div>

                {formData.domicilio === 'Si' && (
                    <div>
                        <label>Dirección:</label>
                        <input
                            type="text"
                            name="direccion"
                            value={formData.direccion}
                            onChange={handleChange}
                        />
                    </div>
                )}
                <div>
                    <label>Email:</label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <label>Decoración:</label>
                    <select name="hasDecoracion" value={formData.hasDecoracion} onChange={handleChange}>
                        <option value="" disabled>Deseas decoraciones</option>
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                </div>

                {
                    formData.hasDecoracion === 'Si' && (
                        <div>
                            <label>Tipo de decoración:</label>
                            <select name="decoracion" value={formData.decoracion} onChange={handleDecoracionChange}>
                                <option value="" disabled>selecciona la decoración que deseas</option>
                                <option value="letreros">Letreros</option>
                                <option value="volcan">volcán</option>
                                <option value="vela">Vela</option>
                                <option value="flores">Flores</option>
                                <option value="stiker">Stikers</option>
                                <option value="acetatomono">Acetatos y Moños</option>
                            </select>
                        </div>
                    )
                }


                {/* Agrega los campos según las condiciones de producto */}
                {
                    ['pasteles'].includes(formData.producto) && (
                        <>
                            <div>
                                <label>Tamaño:</label>
                                <select name="tamano" value={formData.tamano} onChange={handleChange}>
                                    <option value="" disabled>selecciona el tamaño que deseas</option>
                                    <option value="1/8 libra">1/8 libra</option>
                                    <option value="1/4 libra">1/4 libra</option>
                                    <option value="1/2 libra">1/2 libra</option>
                                    <option value="1 libra">1 libra</option>
                                </select>
                            </div>
                            <div>
                                <label>Sabor:</label>
                                <select name="sabor" value={formData.sabor} onChange={handleChange}>
                                    <option value="" disabled>selecciona el sabor que deseas</option>
                                    <optgroup label='Línea Saludable'>
                                        <option value="Manzana">Manzana</option>
                                        <option value="Zanahoria">Zanahoria</option>
                                        <option value="Arándanos">Arándanos</option>
                                        <option value="Almendras">Almendras</option>
                                        <option value="Coco">Coco</option>
                                    </optgroup>
                                    <optgroup label='Línea Creativa'>
                                        <option value="Red Velvet">Red Velvet</option>
                                        <option value="Semillas de Amapola">Semillas de Amapola</option>
                                        <option value="Chocolate">Chocolate</option>
                                        <option value="Vainilla y Coco">Vainilla y Coco</option>
                                        <option value="Vainilla y Chip de Chocolate">Vainilla y Chip de Chocolate</option>
                                        <option value="Choco Brownie">Choco brownie</option>
                                        <option value="Milk Way">Mill Way</option>
                                    </optgroup>
                                </select>
                            </div>
                            <div>
                                <label>Relleno:</label>
                                <select name="relleno" value={formData.relleno} onChange={handleChange}>
                                    <option value="" disabled>selecciona el rellleno que deseas</option>
                                    <option value="Crema napolitana">Crema napolitana</option>
                                    <option value="Manjar blanco">Manjar blanco</option>
                                    <option value="Frutos secos">Frutos secos</option>
                                    <option value="Crema de avellana">Crema de avellana</option>
                                    <option value="Coco">Coco</option>
                                    <option value="Frutos rojos">Frutos rojos</option>
                                    <option value="Frosting de queso">Frosting de queso</option>
                                    <option value="Chocolate">Chocolate</option>
                                    <option value="Arequipe">Arequipe</option>
                                    <option value="Oreo">Oreo</option>
                                </select>
                            </div>
                        </>
                    )
                }
                {
                    ['minicakes'].includes(formData.producto) && (
                        <>
                            <div>
                                <label>Sabor:</label>
                                <select name="sabor" value={formData.sabor} onChange={handleChange}>
                                    <option value="" disabled>selecciona el sabor que deseas</option>
                                    <optgroup label='Línea Saludable'>
                                        <option value="Manzana">Manzana</option>
                                        <option value="Zanahoria">Zanahoria</option>
                                        <option value="Arándanos">Arándanos</option>
                                        <option value="Almendras">Almendras</option>
                                        <option value="Coco">Coco</option>
                                    </optgroup>
                                    <optgroup label='Línea Creativa'>
                                        <option value="Red Velvet">Red Velvet</option>
                                        <option value="Semillas de Amapola">Semillas de Amapola</option>
                                        <option value="Chocolate">Chocolate</option>
                                        <option value="Vainilla y Coco">Vainilla y Coco</option>
                                        <option value="Vainilla y Chip de Chocolate">Vainilla y Chip de Chocolate</option>
                                        <option value="Choco Brownie">Choco brownie</option>
                                        <option value="Milk Way">Mill Way</option>
                                    </optgroup>
                                </select>
                            </div>
                            <div>
                                <label>Relleno:</label>
                                <select name="relleno" value={formData.relleno} onChange={handleChange}>
                                    <option value="" disabled>selecciona el relleno que deseas</option>
                                    <option value="Crema napolitana">Crema napolitana</option>
                                    <option value="Manjar blanco">Manjar blanco</option>
                                    <option value="Frutos secos">Frutos secos</option>
                                    <option value="Crema de avellana">Crema de avellana</option>
                                    <option value="Coco">Coco</option>
                                    <option value="Frutos rojos">Frutos rojos</option>
                                    <option value="Frosting de queso">Frosting de queso</option>
                                    <option value="Chocolate">Chocolate</option>
                                    <option value="Arequipe">Arequipe</option>
                                    <option value="Oreo">Oreo</option>
                                </select>
                            </div>
                        </>
                    )
                }
                {
                    ['porcionTorta'].includes(formData.producto) && (
                        <>
                            <div>
                                <label>Sabor:</label>
                                <select name="sabor" value={formData.sabor} onChange={handleChange}>
                                    <option value="" disabled>selecciona el sabor que deseas</option>
                                    <optgroup label='Línea Saludable'>
                                        <option value="Manzana">Manzana</option>
                                        <option value="Zanahoria">Zanahoria</option>
                                        <option value="Arándanos">Arándanos</option>
                                        <option value="Almendras">Almendras</option>
                                        <option value="Coco">Coco</option>
                                    </optgroup>
                                    <optgroup label='Línea Creativa'>
                                        <option value="Red Velvet">Red Velvet</option>
                                        <option value="Semillas de Amapola">Semillas de Amapola</option>
                                        <option value="Chocolate">Chocolate</option>
                                        <option value="Vainilla y Coco">Vainilla y Coco</option>
                                        <option value="Vainilla y Chip de Chocolate">Vainilla y Chip de Chocolate</option>
                                        <option value="Choco Brownie">Choco brownie</option>
                                        <option value="Milk Way">Mill Way</option>
                                    </optgroup>
                                </select>
                            </div>
                            <div>
                                <label>Relleno:</label>
                                <select name="relleno" value={formData.relleno} onChange={handleChange}>
                                    <option value="" disabled>selecciona el relleno que deseas</option>
                                    <option value="Crema napolitana">Crema napolitana</option>
                                    <option value="Manjar blanco">Manjar blanco</option>
                                    <option value="Frutos secos">Frutos secos</option>
                                    <option value="Crema de avellana">Crema de avellana</option>
                                    <option value="Coco">Coco</option>
                                    <option value="Frutos rojos">Frutos rojos</option>
                                    <option value="Frosting de queso">Frosting de queso</option>
                                    <option value="Chocolate">Chocolate</option>
                                    <option value="Arequipe">Arequipe</option>
                                    <option value="Oreo">Oreo</option>
                                </select>
                            </div>
                        </>
                    )
                }
                {
                    ['agua'].includes(formData.producto) && (
                        <div>
                            <label>Tamaño:</label>
                            <select name="tamano" value={formData.tamano} onChange={handleChange}>
                                <option value="" disabled>selecciona el tamaño que deseas</option>
                                <option value="Grande">Grande</option>
                                <option value="Pequeña">Pequeña</option>
                            </select>
                        </div>
                    )
                }
                {
                    ['sangria'].includes(formData.producto) && (
                        <div>
                            <label>Tamaño:</label>
                            <select name="tamano" value={formData.tamano} onChange={handleChange}>
                                <option value="" disabled>selecciona el tamaño que deseas</option>
                                <option value="Grande">Grande</option>
                                <option value="Pequeña">Pequeña</option>
                            </select>
                        </div>
                    )
                }
                {
                    ['teHatzu'].includes(formData.producto) && (
                        <div>
                            <label>Tamaño:</label>
                            <select name="tamano" value={formData.tamano} onChange={handleChange}>
                                <option value="" disabled>selecciona el tamaño que deseas</option>
                                <option value="Grande">Grande</option>
                                <option value="Pequeña">Pequeña</option>
                            </select>
                        </div>
                    )
                }
                {
                    ['sheesecakes'].includes(formData.producto) && (
                        <>
                            <div>
                                <label>Sabor:</label>
                                <select name="sabor" value={formData.sabor} onChange={handleChange}>
                                    <option value="" disabled>selecciona el sabor que deseas</option>
                                    <option value="Frutos Rojos">Frutos Rojos</option>
                                    <option value="Alchira">Alchira y Arequipe</option>
                                </select>
                            </div>
                        </>
                    )
                }
                {
                    ['cupcakes'].includes(formData.producto) && (
                        <>
                            <div>
                                <label>Sabor:</label>
                                <select name="sabor" value={formData.sabor} onChange={handleChange}>
                                    <option value="" disabled>selecciona el sabor que deseas</option>
                                    <optgroup label='Línea Saludable'>
                                        <option value="Manzana">Manzana</option>
                                        <option value="Zanahoria">Zanahoria</option>
                                        <option value="Arándanos">Arándanos</option>
                                        <option value="Almendras">Almendras</option>
                                        <option value="Coco">Coco</option>
                                    </optgroup>
                                    <optgroup label='Línea Creativa'>
                                        <option value="Red Velvet">Red Velvet</option>
                                        <option value="Semillas de Amapola">Semillas de Amapola</option>
                                        <option value="Chocolate">Chocolate</option>
                                        <option value="Vainilla y Coco">Vainilla y Coco</option>
                                        <option value="Vainilla y Chip de Chocolate">Vainilla y Chip de Chocolate</option>
                                        <option value="Choco Brownie">Choco brownie</option>
                                        <option value="Milk Way">Mill Way</option>
                                    </optgroup>
                                </select>
                            </div>
                            <div>
                                <label>Relleno:</label>
                                <select name="relleno" value={formData.relleno} onChange={handleChange}>
                                    <option value="" disabled>selecciona el relleno que deseas</option>
                                    <option value="Crema napolitana">Crema napolitana</option>
                                    <option value="Manjar blanco">Manjar blanco</option>
                                    <option value="Frutos secos">Frutos secos</option>
                                    <option value="Crema de avellana">Crema de avellana</option>
                                    <option value="Coco">Coco</option>
                                    <option value="Frutos rojos">Frutos rojos</option>
                                    <option value="Frosting de queso">Frosting de queso</option>
                                    <option value="Chocolate">Chocolate</option>
                                    <option value="Arequipe">Arequipe</option>
                                    <option value="Oreo">Oreo</option>
                                </select>
                            </div>
                        </>
                    )
                }
                {/* Agrega el botón de WhatsApp */}
                <button type="button" onClick={handleWhatsApp}>Enviar por WhatsApp</button>
            </form >
        </div >
    );
}

export default OrderForm;
