import React from 'react';
import { FaFacebook, FaInstagram } from 'react-icons/fa';
import './App.css';

const Footer = () => {
    return (
        <footer className="footer-container">
            <div className="social-links">
                <a href="https://www.facebook.com/profile.php?id=100069416582199" target="_blank" rel="noopener noreferrer">
                    <FaFacebook />
                </a>
                <a href="https://www.instagram.com/bakingtimeneiva/" target="_blank" rel="noopener noreferrer">
                    <FaInstagram />
                </a>
            </div>
            <div className="footer-info">
                <p>© {new Date().getFullYear()} Baking Time Neiva. Todos los derechos reservados.</p>
                <p><a href='https://goo.gl/maps/B2HGPcMqxJV8shzD8'>Dirección: Calle 8 n. 25 - 20, Neiva Huila</a></p>
                <p>Horarios de Atención: Lunes - Sábado 09:00 a 12:00 - 14:00 a 19:00</p>
            </div>
            <a className="contact-link" href="mailto:bakingtimeneiva@gmail.com">bakingtimeneiva@gmail.com</a>
            <div className="dark-footer">
                Developed by Neo Software Development
            </div>
        </footer>
    );
};

export default Footer;