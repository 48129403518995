import React, { useState } from "react";
import "./App.css";

const Menu = ({ items }) => {
  const [selectedSize, setSelectedSize] = useState({});

  const handleSizeChange = (productId, size) => {
    setSelectedSize((prevSelectedSize) => ({
      ...prevSelectedSize,
      [productId]: size,
    }));
  };

  return (
    <div className="section-center">
      {items.map((item) => {
        const { id, title, img, desc, sizes, price } = item;

        // Verificar si el título comienza con la palabra "torta"
        const isTorta = title.toLowerCase().startsWith("torta");

        // Verificar si el título comienza con la palabra "agua"
        const isAgua = title.toLowerCase().startsWith("agua");

        // Verificar si el título comienza con la palabra "té"
        const isTe = title.toLowerCase().startsWith("té");

        // Verificar si el título comienza con la palabra "Sangría"
        const isSangria = title.toLowerCase().startsWith("sangría");

        // Obtener el tamaño seleccionado para este producto
        const selectedProductSize = selectedSize[id] || (sizes && sizes.length > 0 ? sizes[0].size : "");

        // Calcular el precio en función del tamaño seleccionado o mantener el precio original
        const calculatedPrice = isTorta
          ? sizes.find((sizeInfo) => sizeInfo.size === selectedProductSize)?.price || price
          : isAgua
            ? sizes.find((sizeInfo) => sizeInfo.size === selectedProductSize)?.price || price
            : isTe
              ? sizes.find((sizeInfo) => sizeInfo.size === selectedProductSize)?.price || price
              : isSangria
                ? sizes.find((sizeInfo) => sizeInfo.size === selectedProductSize)?.price || price
                : price;

        return (
          <article key={id} className="menu-item">
            <div className="item-info">
              <img src={img} alt={title} className="photo" />
              {(isTorta || isAgua || isTe || isSangria) && sizes && sizes.length > 0 && (
                <div className="size-buttons">
                  {sizes.map((sizeInfo) => (
                    <button
                      key={sizeInfo.size}
                      onClick={() => handleSizeChange(id, sizeInfo.size)}
                      className={selectedProductSize === sizeInfo.size ? "active" : ""}
                    >
                      {sizeInfo.size}
                    </button>
                  ))}
                </div>
              )}
            </div>

            <div className="item-info">
              <header>
                <h4>{title}</h4>
                <h4 className="price">${calculatedPrice}</h4>
              </header>
              <p className="item-text">{desc}</p>
            </div>
          </article>
        );
      })}
    </div>
  );
};

export default Menu;
