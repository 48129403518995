import React, { useState } from "react";
import { GiCupcake } from "react-icons/gi";
import "./App.css"; // Import your CSS file

const Categories = ({ categories, filterItems, activeCategory }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className={`categories-container ${isDropdownOpen ? "active" : ""}`}>
      <button
        className="hamburger-icon"
        onClick={toggleDropdown}
      >
        <GiCupcake />
      </button>

      <div className={`dropdown-menu ${isDropdownOpen ? "open" : ""}`}>
        {categories.map((category, index) => (
          <button
            type="button"
            className={`${
              activeCategory === category ? "filter-btn active" : "filter-btn"
            }`}
            key={index}
            onClick={() => {
              filterItems(category);
              setIsDropdownOpen(false);
            }}
          >
            {category}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Categories;
