const menu = [
  {
    id: 1,
    title: 'Cup Cake',
    category: 'Pasabocas',
    price: 4000,
    img: `https://bakingtimeneiva.com/wp-content/imagenes-productos-menu/item-1.jpeg`,
    desc: `Nuestros cupcakes son la manera perfecta de endulzar tu día. Están hechos de ingredientes frescos y de alta calidad, y vienen en una variedad de sabores deliciosos.`,
  },
  {
    id: 2,
    title: 'Porción de torta',
    category: 'Pasabocas',
    price: 10000,
    img: 'https://bakingtimeneiva.com/wp-content/imagenes-productos-menu/item-2.jpeg',
    desc: `Encontraras la delicia de nuestras tortas, en su rellenos fríos en tres presentaciones; red velvet, tres leches frutal, y chocobrownie`,
  },
  {
    id: 3,
    title: 'Postres cuchareables vidrio',
    category: 'Pasabocas',
    price: 12000,
    img: 'https://bakingtimeneiva.com/wp-content/imagenes-productos-menu/item-3.jpeg',
    desc: `Disfruta de nuestros deliciosos postres cuchareables presentación de vidrio, perfectos para compartir o para disfrutar tú solo. tenemos una variedad de sabores para elejir. ¿A qué esperas? ¡Pide tu postre cuchareable Ya!`,
  },
  {
    id: 4,
    title: 'Postres cuchareables plástico',
    category: 'Pasabocas',
    price: 10000,
    img: 'https://bakingtimeneiva.com/wp-content/imagenes-productos-menu/item-4.jpg',
    desc: `Disfruta de nuestros deliciosos postres cuchareables presentación de plástico, perfectos para compartir o para disfrutar tú solo. tenemos una variedad de sabores para elejir. ¿A qué esperas? ¡Pide tu postre cuchareable Ya!`,
  },
  {
    id: 5,
    title: 'Galletas',
    category: 'Pasabocas',
    price: 1500,
    img: 'https://bakingtimeneiva.com/wp-content/imagenes-productos-menu/item-5.jpg',
    desc: `Nuestras galletas hojaldradas de avena u de harina tradicional en forma de corazón, son una delicia artesanal para degustaciones o eventos.`,
  },
  {
    id: 6,
    title: 'Obleas de colores *12',
    category: 'Pasabocas',
    price: 20000,
    img: 'https://bakingtimeneiva.com/wp-content/imagenes-productos-menu/item-6.jpg',
    desc: `Una delicia crujiente en presentación mediana y de diferentes colores, ahora las puedes pedir con relleno de arequipe, chocolate, mora o frutos rojos, son ideales para eventos, pasabocas y sobre todo nuestros niños la disfrutan`,
  },
  {
    id: 7,
    title: 'Cheesecake frutos rojos',
    category: 'Pasabocas',
    price: 10000,
    img: 'https://bakingtimeneiva.com/wp-content/imagenes-productos-menu/item-7.jpg',
    desc: `Disfruta de nuestro delicioso cheesecake de frutos rojos, un postre clásico y elegante que es perfecto para cualquier ocasión. La base de galleta es crujiente y mantecosa, y el relleno de queso crema es cremoso y delicioso. La capa superior de frutos rojos frescos le da un toque de sabor ácido y refrescante.`,
  },
  {
    id: 8,
    title: 'Cheesecake de achira y arequipe',
    category: 'Pasabocas',
    price: 10000,
    img: 'https://bakingtimeneiva.com/wp-content/imagenes-productos-menu/item-8.jpeg',
    desc: `Disfruta de nuestro delicioso cheesecake de alchiras y arequipe, un postre único y autóctono que combina lo mejor de la región opita. La base crocante del alchira, y el relleno de arequipe es cremoso y delicioso. El resultado es un postre irresistible que te hará la boca agua.`,
  },
  {
    id: 9,
    title: 'Torta',
    category: 'Pasabocas',
    price: 40000,
    img: 'https://bakingtimeneiva.com/wp-content/imagenes-productos-menu/item-9.jpg',
    desc: `skateboard fam synth authentic semiotics. Live-edge lyft af, edison bulb yuccie crucifix microdosing.`,
    sizes: [
      { size: "1/8 libra", price: 25000 },
      { size: "1/4 libra", price: 40000 },
      { size: "1/2 libra", price: 60000 },
      { size: "1 libra", price: 100000 },
    ],
  },
  {
    id: 10,
    title: 'Minicakes',
    category: 'Pasabocas',
    price: 18000,
    img: 'https://bakingtimeneiva.com/wp-content/imagenes-productos-menu/item-10.jpg',
    desc: `Disfruta de nuestros deliciosos minicakes, la manera perfecta de disfrutar de una variedad de sabores de pastel `,
  },
  {
    id: 11,
    title: 'Torta de zanahoria',
    category: 'Línea Saludable',
    price: 100000,
    img: 'https://bakingtimeneiva.com/wp-content/imagenes-productos-menu/item-11.jpg',
    desc: `Disfruta de nuestro delicioso pastel de zanahoria, un postre clásico y delicioso que es perfecto para cualquier ocasión. El bizcocho húmedo y esponjoso está hecho con zanhorias ralladas, especias y nueces, y con cubierta al gusto de nuestros clientes. Es el postre perfecto para los amantes de las zanahorias y los sabores otoñales. ¿A qué esperas? ¡Pide tu pastel de zanahoria hoy mismo!`,
    sizes: [
      { size: "1/8 libra", price: 30000 },
      { size: "1/4 libra", price: 48000 },
      { size: "1/2 libra", price: 70000 },
      { size: "1 libra", price: 110000 },
    ],
  },
  {
    id: 12,
    title: 'Torta de chocolate semiamargo',
    category: 'Línea Saludable',
    price: 100000,
    img: 'https://bakingtimeneiva.com/wp-content/imagenes-productos-menu/item-12.jpg',
    desc: `Nuestro pastel de chocolate semiamargo es perfecto para los amantes del chocolate con un sabor intenso. El bizcocho húmedo y esponjoso está hecho con chocolate semiamargo de alta calidad, y está cubierto con una deliciosa ganache de chocolate. Es el postre perfecto para los amantes al chocolate y los sabores decadentes. ¿A qué esperas? ¡Pide tu pastel de zanahoria hoy mismo!`,
    sizes: [
      { size: "1/8 libra", price: 30000 },
      { size: "1/4 libra", price: 48000 },
      { size: "1/2 libra", price: 70000 },
      { size: "1 libra", price: 110000 },
    ],
  },
  {
    id: 13,
    title: 'Torta de manzana',
    category: 'Línea Saludable',
    price: 100000,
    img: 'https://bakingtimeneiva.com/wp-content/imagenes-productos-menu/item-13.jpeg',
    desc: `Nuestro pastel de manzana es un clásico postre que es perfecto para cualquier ocasión. El bizcocho húmedo y esponjoso está relleno de manzanas frescas, canela y azúcar morena, está cubierto con una deliciosa costra de hojaldre. Es el postre perfecto para los amantes de las manzanas y sabores otoñales. ¿A qué esperas? ¡Pide tu pastel de zanahoria hoy mismo!`,
    sizes: [
      { size: "1/8 libra", price: 30000 },
      { size: "1/4 libra", price: 48000 },
      { size: "1/2 libra", price: 70000 },
      { size: "1 libra", price: 110000 },
    ],
  },
  {
    id: 14,
    title: 'Torta de almendras sin gluten',
    category: 'Línea Saludable',
    price: 100000,
    img: 'https://bakingtimeneiva.com/wp-content/imagenes-productos-menu/item-14.jpeg',
    desc: `Nuestro pastel de almendras sin gluten es el postre perfecto para las personas con sensibilidad al gluten. El bizcocho húmedo y esponjoso está hecho con harina de almendras, azúcar y huevos, con cubiertas glaseadas de almendras. Es el postre perfecto para los amantes de las almendras, nueces y sabores clásicos. ¿A qué esperas? ¡Pide tu pastel de zanahoria hoy mismo!`,
    sizes: [
      { size: "1/8 libra", price: 30000 },
      { size: "1/4 libra", price: 48000 },
      { size: "1/2 libra", price: 70000 },
      { size: "1 libra", price: 110000 },
    ],
  },
  {
    id: 15,
    title: 'Torta de avena',
    category: 'Línea Saludable',
    price: 100000,
    img: 'https://bakingtimeneiva.com/wp-content/imagenes-productos-menu/item-15.jpg',
    desc: `Nuestro pastel de avena es el postre perfecto para los amantes de la avena y los sabores saludables. El bizcocho húmedo y esponjoso está hecho con avena, huevos, azúcar y leche, y está cubierto con una deliciosa crema de avena. Es el postre perfecto para los que buscan una alternativa saludable y deliciosa a los postres tradicionales. ¿A qué esperas? ¡Pide tu pastel de zanahoria hoy mismo!`,
    sizes: [
      { size: "1/8 libra", price: 30000 },
      { size: "1/4 libra", price: 48000 },
      { size: "1/2 libra", price: 70000 },
      { size: "1 libra", price: 110000 },
    ],
  },
  {
    id: 16,
    title: 'Torta de vino',
    category: 'Línea Creativa',
    price: 95000,
    img: 'https://bakingtimeneiva.com/wp-content/imagenes-productos-menu/item-16.jpg',
    desc: `Nuestro pastel de vino es el postre perfecto para los amantes del vino y lo sabores únicos. El bizcocho húmedo y esponjoso está hecho con vino tinto, especias y frutos secos, y está cubierto con una deliciosa crema de chocolate. Es el postre perfecto para ocasiones especiales o simplemente para disfrutar de un momento de indulgencia. ¿A qué esperas? ¡Pide tu pastel de vino hoy mismo!`,
    sizes: [
      { size: "1/8 libra", price: 25000 },
      { size: "1/4 libra", price: 40000 },
      { size: "1/2 libra", price: 60000 },
      { size: "1 libra", price: 100000 },
    ],
  },
  {
    id: 17,
    title: 'Torta de semillas de amapola',
    category: 'Línea Creativa',
    price: 95000,
    img: 'https://bakingtimeneiva.com/wp-content/imagenes-productos-menu/item-17.jpg',
    desc: `Nuestro pastel de semillas de amapola es el postre perfecto para los amantes de las semillas de amapola y los sabores delicados. El bizcocho húmedo y esponjoso está hecho con semillas de amapola, leche, huevos y azúcar, y está cubierto con una deliciosa crema de semillas de amapola. Es el postre perfecto para ocasiones especiales o simplemente para disfrutar de un momento de indulgencia. ¿A qué esperas? ¡Pide tu pastel de semillas de amapola hoy mismo!`,
    sizes: [
      { size: "1/8 libra", price: 25000 },
      { size: "1/4 libra", price: 40000 },
      { size: "1/2 libra", price: 60000 },
      { size: "1 libra", price: 100000 },
    ],
  },
  {
    id: 18,
    title: 'Torta de chocolate',
    category: 'Línea Creativa',
    price: 95000,
    img: 'https://bakingtimeneiva.com/wp-content/imagenes-productos-menu/item-18.jpg',
    desc: `Nuestro pastel de chocolate es el postre perfecto para los amantes del chocolate y los sabores intensos. El bizcocho húmedo y esponjoso está hecho con chocolate de alta calidad, y está hecho con chocolate de alta calidad, y está cubierto con una deliciosa ganache de chocolate. Es el postre perfecto para ocasiones especiales o simplemente para disfrutar de un momento de indulgencia. ¿A qué esperas? ¡Pide tu pastel de semillas de amapola hoy mismo!`,
    sizes: [
      { size: "1/8 libra", price: 25000 },
      { size: "1/4 libra", price: 40000 },
      { size: "1/2 libra", price: 60000 },
      { size: "1 libra", price: 100000 },
    ],
  },
  {
    id: 19,
    title: 'Torta de red velvet',
    category: 'Línea Creativa',
    price: 95000,
    img: 'https://bakingtimeneiva.com/wp-content/imagenes-productos-menu/item-19.jpg',
    desc: `Nuestro pastel Red Velvet es el postre perfecto para los amantes de los sabores únicos y decadentes. El bizcocho húmedo y esponjoso está hecho con cacao, vinagre balnco y colorante rojo, y está cubierto con una deliciosa crema de queso. Es el postre perfecto para ocasiones especiales o simplemente para disfrutar de un momento de indulgencia. ¿A qué esperas? ¡Pide tu pastel de semillas de amapola hoy mismo!`,
    sizes: [
      { size: "1/8 libra", price: 25000 },
      { size: "1/4 libra", price: 40000 },
      { size: "1/2 libra", price: 60000 },
      { size: "1 libra", price: 100000 },
    ],
  },
  {
    id: 20,
    title: 'Agua',
    category: 'Otras',
    price: 2500,
    img: 'https://bakingtimeneiva.com/wp-content/imagenes-productos-menu/item-20.jpg',
    desc: `Agua fresca y pura para refrescarte en culquier momento, presentación pequeña`,
    sizes: [
      {size: 'Pequeña', price: 2500},
      {size: 'Grande', price: 3500},
    ],
  },
  {
    id: 21,
    title: 'Té hatsu',
    category: 'Otras',
    price: 5000,
    img: 'https://bakingtimeneiva.com/wp-content/imagenes-productos-menu/item-21.jpg',
    desc: `Té Hatzu es una bebida deliciosa y saludable hecha con ingredientes naturales. Nuestras tés están hechas con té de alta calidad, frutas, hierbas y especias cuidadosamente seleccionadas.`,
    sizes: [
      {
        size: 'Pequeña',
        price: 5000,
      },
      {
        size: 'Grande',
        price: 7000,
      },
    ],
  },  
  {
    id: 22,
    title: 'Latte',
    category: 'Otras',
    price: 5500,
    img: 'https://bakingtimeneiva.com/wp-content/imagenes-productos-menu/item-22.jpg',
    desc: `Bebida de café hecha con espresso y leche vaporizada. La leche se agrega al espresso en una porción de 1:3. Los lattes suelen estar coronados con una fina capa de leche espumosa.`,
  },
  {
    id: 23,
    title: 'Café expresso',
    category: 'Otras',
    price: 3000,
    img: 'https://bakingtimeneiva.com/wp-content/imagenes-productos-menu/item-23.jpg',
    desc: `Bebida de café concentrada que se prepara forzando agua caliente a alta presión a tráves de granos de café molidos muy finos. Disfruta de un sabor intenso y rico. Será una bebida popular para los amantes del café que buscan una experiencia de café fuerte y satisfactoria.`,
  },
  {
    id: 24,
    title: 'Soda frutos rojos',
    category: 'Otras',
    price: 4500,
    img: 'https://bakingtimeneiva.com/wp-content/imagenes-productos-menu/item-24.jpg',
    desc: `Bebida refrescante y deliciosa que es perfecta para cualquier momento del día. Está hecha con agua carbonatada, jarabe de frutos rojos y trozos de fruta fresca.`,
  },
  {
    id: 25,
    title: 'Sangría',
    category: 'Otras',
    price: 17000,
    img: 'https://bakingtimeneiva.com/wp-content/imagenes-productos-menu/item-25.jpg',
    desc: `Bebida deliciosa y refrescante que es perfecta para disfrutar en cualquier ocasión. Con un sabor único y sus ingredientes frescos, la sangría es una bebida que seguramente disfrutará.`,
    sizes: [
      { size: 'Pequeño', price: 17000 },
      { size: 'Grande', price: 38000 }
    ]
  },  
];
export default menu;