import React from 'react';
import './App.css';

const cakeFlavors = () => {
  // Lista de sabores de pasteles
  const flavors = [
    'Red velvet',
    'Semillas de amapola',
    'Chocolate',
    'Vainilla y coco',
    'Vainilla y chip de chocolate',
    'Choco brownie',
    'Milk way',
  ];

  return (
    <div className="sabores-container">
      <h2>Sabores de Pasteles</h2>
      <h3>Masas:</h3>
      <div className="sabores-grid">
        {flavors.map((flavor, index) => (
          <div key={index} className="sabor-cuadro">
            {flavor}
          </div>
        ))}
      </div>
    </div>
  );
};

const healthyFlavors = () => {
  const healthy = [
    'Manzana',
    'Zanahoria',
    'Arándanos',
    'Almendras',
    'Coco'
  ];

  return (
    <div className="sabores-container">
      <h2>Sabores Saludables</h2>
      <h3>Masas:</h3>
      <div className="sabores-grid">
        {healthy.map((flavor, index) => (
          <div key={index} className="sabor-cuadro">
            {flavor}
          </div>
        ))}
      </div>
    </div>
  );
};

const stuffedFlavors = () => {
  // Lista de sabores de rellenos
  const StuffedFlavors = [
    'Crema napolitana',
    'Manjar blanco',
    'Frutos secos',
    'Crema de avellana',
    'Coco',
    'Frutos rojos',
    'Frosting de queso',
    'Chocolate',
    'Arequipe',
    'Oreo',
  ];

  return (
    <div className="sabores-container">
      <h3>Rellenos:</h3>
      <div className="sabores-grid">
        {StuffedFlavors.map((flavor, index) => (
          <div key={index} className="sabor-cuadro">
            {flavor}
          </div>
        ))}
      </div>
    </div>
  );
};

export { cakeFlavors as CakeFlavors, healthyFlavors as HealthyFlavors, stuffedFlavors as StuffedFlavors };
